

































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import GamesListDesktop from './GamesListDesktop.vue'
import GamesListMobile from './GamesListMobile.vue'
// eslint-disable-next-line no-unused-vars
import { Game } from '@/store/types'
import gql from "graphql-tag"
import Loader from './Loader.vue';
import Button from '../global/Button.vue'
import MobileAggregatorModal from './MobileAggregatorsModal.vue'

const cookies = require('js-cookie')
const GameGetter = namespace('game').Getter
const GameAction = namespace('game').Action
const AccountGetter = namespace('account').Getter
const SettingsGetter = namespace('settings').Getter
interface CategoriesList {
  id: number,
  title: string
}
const AppProps = Vue.extend({
  data: () => {
    return {
      gameList: [],
      isMobile: false,
      isSticky: false,
      promoSeen: false,
      stickyThreshold: null,
      gamesLoading: true,
      categoriesStub: [] as CategoriesList[],
      titleSearch: '',
      allGames: [],
      promoGames: [],
      featuredGames: [] as Game[],
      filteredAggregatorGames: [],
      activeCategory: null,
      casino_code: cookies.get('casino_code'),
      renderAggregatorModal: false,
      aggregators: {},
      deviceTypes: {
        'DESKTOP': false,
        'MOBILE': false,
        'DESKTOP_AND_MOBILE': true
      },
      renderSearchIcons: window.innerWidth < 1200,
      hideCategoryStubs: false,
      renderMobileAggregator: false
    }
  },
  filters: {
    localizedCategoryTitle(category, locale) {
      const titleLocalized = category['title_' + locale]
      return (locale !== 'en' && titleLocalized) ? titleLocalized : category.title
    }
  },
  watch: {
    activeCategory: function () {
      this.retrieveAggregators();
      this.resetAggregatorFilters();
    }
  },
  methods: {
    async retrieveGames (cat, scrollIntoView = false) {
      if(cat.id == 55) {
        this.promoSeen = true
      }
      const mapFavorite = game => {
        game.favorite = this.favoriteGames.indexOf(game.internalGameId) != -1;
        if (cat.id === 7) {
          game.badge = '/images/common/badge_dropsandwins.png';
        }
        return game
      }
      this.activeCategory = cat
      this.gamesLoading = true
      await this.getPublicGamesList()
      this.updateGameList();
      if (this.gameList.length === 0) {
        if (this.userId) {
          await this.getFavorites()
        }
      }
      // To get the categories
      // cat 99 is favorites
      // cat 55 is Drops and Wins
      if(cat.id == 55) {
        this.allGames = this.gameList.filter(game => game.enabled == true && game.promo == 'true').map(mapFavorite)
        this.featuredGames = []
        this.gamesLoading = false
      } else if (cat.id === 1) {
        this.promoGames = this.gameList.filter(game => game.enabled == true && game.promo == 'true').map(mapFavorite)
        this.allGames = this.gameList.filter(game => game.enabled == true && game.featured == 'false').map(mapFavorite)
        this.featuredGames = this.gameList.filter(game => game.enabled == true && game.featured == 'true').map(mapFavorite)
        this.gamesLoading = false
      }
      else if(cat.id != 99) {
        this.promoGames =  this.gameList.filter(game => game.enabled == true && game.promo == 'true').map(mapFavorite)
        this.allGames = this.gameList.filter(game => game.category.indexOf(cat.id.toString()) != -1 && game.enabled == true && game.featured == 'false')
        .map(mapFavorite)
        this.featuredGames = this.gameList.filter(game => game.category.indexOf(cat.id.toString()) != -1 && game.enabled == true && game.featured == 'true')
        .map(mapFavorite)
        this.gamesLoading = false
      }
      else {
        // To get the favorites
        this.allGames = this.gameList.filter(game => game.enabled == true && game.featured == 'false')
        .map(mapFavorite).filter(game => game.favorite == true)
        this.featuredGames = this.gameList.filter(game => game.enabled == true && game.featured == 'true')
        .map(mapFavorite).filter(game => game.favorite == true)
        this.gamesLoading = false
      }
      if(cat.id == 56) {
        this.allGames = this.gameList.filter(game => game.enabled == true && game.tournament == 'true').map(mapFavorite)
        this.featuredGames = []
        this.gamesLoading = false
      }
      // Filter out the games with no category assigned
      if (cat.id == 1) {
        this.allGames = this.gameList.filter(game => game.category.indexOf(cat.id.toString()) != -1 && game.enabled == true)
        this.gamesLoading = false
      }

      // Sort the games using priority
      this.promoGames = this.sortGamesUsingPriority(this.promoGames);
      this.allGames = this.sortGamesUsingPriority(this.allGames);
      this.featuredGames = this.sortGamesUsingPriority(this.featuredGames);

      if (scrollIntoView) {
        try {
          let categoryMenu = document.getElementById('category-menu')
          let categoryBar = document.getElementById('game-category-bar')
          let categoryBarTabs = document.getElementById('game-category-tabs')
          let selectedCategoryTab = document.getElementById(`cat_${cat.id}`)
          const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
          const scrollDistanceTop = viewportWidth <= 1000
            ? categoryMenu.offsetTop - categoryBar.clientHeight
            : categoryMenu.offsetTop + 60
          window.scrollTo({
            top: scrollDistanceTop,
            left: 0,
            behavior: 'smooth'
          });
          // Scroll to the selected category on mobile if user landed directly on that one
          this.$nextTick(() => {
            if (this.$route.query.cat_id) {
              let scrollDistanceLeft = ((selectedCategoryTab.offsetLeft + selectedCategoryTab.clientWidth + this.categoriesStub.length * 2) >= categoryBarTabs.scrollWidth)
                      ? categoryBarTabs.scrollWidth + selectedCategoryTab.clientWidth
                      : selectedCategoryTab.offsetLeft - selectedCategoryTab.clientWidth / 2;
              categoryBarTabs.scrollTo({
                top: 0,
                left: scrollDistanceLeft,
                behavior: 'smooth'
              });
            }
          })
        } catch(e) {
          window.scrollTo(0, document.getElementById('category-menu').offsetTop)
        }
      }
    },
    retrieveAggregators() {
      // gets a list of unique aggregator names
        this.aggregators = this.gameList.reduce((a, c) => {
          // Filtering out intermittent provider test values from Horizon
          if (a[c.providerId] === undefined
            && !c.providerId.startsWith('uat_')
            && !c.providerId.startsWith('ui_')
          ) {
            if (this.activeCategory) {

              switch (this.activeCategory.id.toString()) {

                // All category are to be shown. 1 pertains to All category.
                case '1':
                  break;
                // Favorites category to be shown. Only show aggregators for
                // games marked as favorite.
                case '99':
                  if (!this.favoriteGames.includes(c.internalGameId)) {
                    return a;
                  }
                  break;
                // A specific category was selected. Check if the game matches the
                // current category. If not, don't add the aggregator to the mapping.
                default:
                  if (!c.category.includes(this.activeCategory.id.toString())) {
                    return a;
                  }
                  break;
              }
            }
            a[c.providerId] = false
          }
          return a
        }, {})
    },
    filterOutCategoryTabs() {
      this.categoriesStub.forEach(cat => {
        cat.id = cat.category_id;
        cat.visible = true;
        if(cat.id !== 99) {
          cat.visible = this.gameList.filter(game => game.category.indexOf(cat.id.toString()) != -1 && game.enabled == true && game.featured == 'false').length;
        }

        // Hide favorites if the user is not logged in
        if(cat.id === 99) {
          cat.visible = this.isLoggedIn
        }

        if(cat.id === 56) {
          cat.visible = this.gameList.filter(game => game.enabled == true && game.tournament == 'true').length;
        }
        if(cat.id === 55) {
          cat.visible = this.gameList.filter(game => game.enabled == true && game.promo == 'true').length;
        }
        if(cat.id === 9) {
          cat.visible = this.gameList.filter(game => game.enabled == true).length;
        }
      });
      this.categoriesStub = this.categoriesStub.filter(c => c.visible);
    },
    initStickyThreshold () {
      if (document.getElementById('category-menu') === null) return
      if (!this.isSticky) {
        const distance = window.pageYOffset + document.getElementById('category-menu').getBoundingClientRect().top
        const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
        this.stickyThreshold = viewportWidth <= 1000
          ? distance - document.getElementById('header-top').clientHeight
          : distance
      }
    },
    checkStickyThreshold (resetThreshold = false) {
      if (resetThreshold) this.isSticky = false
      this.initStickyThreshold()
      this.isSticky = window.scrollY >= this.stickyThreshold
    },
    stickyMenu () {
      const vm = this
      window.addEventListener('scroll', () => {
        vm.checkStickyThreshold(false)
      }, false)
      window.addEventListener('resize', () => {
        vm.checkStickyThreshold(true)
      }, false);
    },

    selectedAggregator(name): void {
      this.aggregators[name] = !this.aggregators[name]
    },

    resetAggregatorFilters(): void {
      // eslint-disable-next-line
      for (const [key, _] of Object.entries(this.aggregators)) {
        this.aggregators[key] = false
      }

      this.filteredAggregatorGames = []
    },

    applyAggregatorFilter() {
      this.renderAggregatorModal = false
      if (!this.activeCategory) {
        this.activeCategory = this.categoriesStub.find(category => category.id === 1)
      }

      let selectedAggregators: string[] = []
      // eslint-disable-next-line
      for (const [k, v] of Object.entries(this.aggregators)) {
        // creates an array of user selected aggregators
        if (v) selectedAggregators = [...selectedAggregators, k]
      }

      this.filteredAggregatorGames = this.gameList.filter(game => {
        if (selectedAggregators.includes(game.providerId) && game.enabled == true) {
          // Allow filtering of games marked as favorite.
          if (this.activeCategory.id.toString() === '99') {
            return this.favoriteGames.includes(game.internalGameId);
          }
          return game.category.includes(this.activeCategory.id.toString());      
        }
      })
    },
    removeAggregator(name): void {
      // removes from game list
      this.filteredAggregatorGames = this.filteredAggregatorGames.filter(game => {
        return game.providerId !== name
      })

      // unselects inside modal
      this.aggregators[name] = false
    },
    parseText(text): void {
      return text.toLowerCase().replaceAll(' ', '')
    },

    filteredGames(games): object[] {
      return games.filter(game => {
        // filter out games without demo if user not logged in
        if (!this.$store.getters['account/isLoggedIn'] && (!game.hasDemoMode && this.activeCategory.id !== 6) && game.isExclusive == 'false') {
          return false
        }

        return this.parseText(game.fullGameName).includes(this.parseText(this.titleSearch))
      })
    },

    clearSearch(): void {
      this.titleSearch = ""
      this.resetAggregatorFilters()
    },

    updateGameList() {
      let selectedDeviceTypes: string[] = []
      // eslint-disable-next-line
      for (const [k, v] of Object.entries(this.deviceTypes)) {
        // creates an array of user selected aggregators
        if (v) selectedDeviceTypes = [...selectedDeviceTypes, k]
      }
      this.gameList = this.games.filter(game => {
        if (selectedDeviceTypes.includes(game.type) && game.enabled == true) {
          return game
        }
      });
    },

    sortGamesUsingPriority(games) {
      games = games.sort(function (a, b) {
        let priorityA = a.priority;
        let priorityB = b.priority;
        return priorityA > priorityB ? 1 : -1;
      });
      return games;
    }
  },

  computed: {
    categoryTitle() {
      if (this.activeCategory.id === 56) {
        return 'Tournament'
      }
      // 55 = promo games category number
      return this.activeCategory.id != 55 ? this.$options.filters.localizedCategoryTitle(this.activeCategory, this.$i18n.locale) : this.$i18n.t('2_million_daily_drops')
    },

    listAggregators(): string[] {
      const keys = Object.keys(this.aggregators)

      return keys.filter(key => {
        return this.aggregators[key]
      })
    },

    aggregatorFilterCount(): number {
      let count = 0
      Object.values(this.aggregators).forEach(value => value ? count++ : null)

      return count
    }
  },
  async mounted () {
    window.onresize = () => {
      this.renderSearchIcons = window.innerWidth < 1440
    }

    this.isMobile = /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent);

    if(this.isMobile) {
      this.deviceTypes['MOBILE'] = true;
      this.deviceTypes['DESKTOP'] =false;
    } else {
      this.deviceTypes['MOBILE'] = false;
      this.deviceTypes['DESKTOP'] =true;
    }

    let categoriesResult = await this.$apollo.query({ query: gql`
      query HomePageMenu($casinoCode: String!) {
        casinos(where: { name: $casinoCode }) {
          main_menu_categories {
              title
              title_pt
              title_ru
              title_es
              category_id
              order
              excludeFrom
          }
        }
      }
    `,
    variables: {
      casinoCode: this.casino_code
    }
    })
    this.categoriesStub = categoriesResult.data.casinos[0].main_menu_categories
      .sort((a, b) => parseInt(a.order) - parseInt(b.order));

    this.categoriesStub = this.categoriesStub.filter(c => {
      if (c.excludeFrom) {
        //@ts-ignore
        return !c.excludeFrom.includes(this.userCountryCode);
      }
      return true;
    });
    this.categoriesStub.forEach(cat => {
      cat.id = cat.category_id
    });
    let categoryStubIndex = 0;
    if(this.$route.query.windrops !== undefined && this.getPlatformCurrency == 'BTC') categoryStubIndex = 5;
    else if(this.$route.query.jackpot !== undefined) categoryStubIndex = 4;

    if (this.isLoggedIn) {
      if (!this.isMobile) {
        await this.retrieveGames(this.categoriesStub[categoryStubIndex])
      } else {
        await this.retrieveGames(this.categoriesStub[2])
      }
    } else {
      await this.retrieveGames(this.categoriesStub[2])
    }
    this.filterOutCategoryTabs()
    this.hideCategoryStubs = false
    this.$nextTick(function () {
      let urlParams = this.$route.query;
      let categorySelected = urlParams.cat_id;

      if(categorySelected) {
        $(`#cat_${categorySelected}`).trigger('click');
      }
    })
    this.retrieveAggregators()
    this.stickyMenu()
  },
  props: {}
})
@Component({
  components: {
    GamesListDesktop,
    GamesListMobile,
    Loader,
    Button,
    MobileAggregatorModal
  }
})
export default class HomeGameMenu extends AppProps {
  @GameAction getPublicGamesList
  @GameAction getFavorites
  @GameGetter games
  @GameGetter favoriteGames
  @AccountGetter userId
  @AccountGetter userToken!: String
  @AccountGetter isLoggedIn
  @AccountGetter userCountryCode
  @SettingsGetter getPlatformCurrency
}
